<template>
  <div>
    <br />
    <br />
    <br />
    <div class="row mt-3">
      <div class="col-md-8 mx-auto">
        <h4 class="font-weight-bold text-uppercase" style="color: #1d4374">
          {{ data.title }}
        </h4>
        <span
          style="
            background-color: #007bff;
            border-radius: 5px;
            color: white;
            padding: 3px;
          "
        >
          <small>
            <strong>
              {{ data.created_at | moment("dddd, Do MMMM  YYYY, h:mm a") }}
            </strong>
          </small>
        </span>
        <hr />
        <carousel :per-page="1">
          <slide v-for="item in extractGallery(data.file)" :key="item">
            <img
              v-if="item"
              style="width: 100%"
              :src="item"
              class="mb-3"
              alt=""
              srcset=""
            />
          </slide>
        </carousel>

        <hr />
        <div v-html="data.description"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  props: ["data"],
  methods: {
    extractGallery(item) {
      return item.split(",");
    },
  },
};
</script>

<style scoped>
</style>