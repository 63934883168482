<template>
  <div>
    <br /><br />
    <h4 class="mt-5 text-center font-weight-bold text-primary">
      <b> Gallery Bappeda Kota Pagaralam </b>
    </h4>
    <br /><br />
    <section>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <template v-for="item in gallery">
          <div :key="item.id" class="col">
            <div class="card rounded shadow m-2">
              <carousel :per-page="1">
                <slide v-for="item in extractGallery(item.file)" :key="item">
                  <a :href="item" target="_blank" rel="noopener noreferrer">
                    <img
                      :src="item"
                      class="card-img-top"
                      style="height: 250px; object-fit: cover"
                      alt="..."
                    />
                  </a>
                </slide>
              </carousel>

              <div class="card-footer">
                <p class="text-primary">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </section>
    <br /><br />
    <modal
      :clickToClose="false"
      class="bg-transparent"
      height="auto"
      width="150px"
      name="loading"
    >
      <div class="p-4">
        <div class="d-flex flex-column justify-content-center text-center">
          <img height="100px" src="@/assets/loading.svg" alt="" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      params: {
        sorttype: "desc",
        sortby: "id",
      },
      gallery: [],
    };
  },

  methods: {
    getData() {
      this.$modal.show("loading");
      setTimeout(() => {
        this.$store
          .dispatch("data/getGallery", this.params)
          .then((resp) => {
            this.gallery = resp.data.data;
            this.$modal.hide("loading");
          })
          .catch((e) => {
            this.$toast.error(e);
            this.$modal.hide("loading");
          });
      }, 2000);
    },

    extractGallery(item) {
      return item.split(",");
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.text-primary {
  color: #0a468f !important;
}

hr {
  color: #0a468f;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100px;
  height: 4px;
  opacity: 100%;
  border-radius: 100px;
}
body {
  width: 100% !important;
}
</style>